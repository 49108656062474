import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import i18n from '../../../i18n'
import getUserLocale from "get-user-locale";

const LessonActions = ({ id, state, start, event, is_passed }) => {



  const user = useSelector(state => state.user.userData)

  if (state == "booked") {
    if (user.role == "Teacher") {
      return (
        <>
          <Link className="lesson-state-actions" to={`?l=${id}&pm=m&state=ask_for_cancel`}>{i18n.t('views.lessons.actions.ask_for_cancel')}</Link>
          <Link className="lesson-state-actions" to={`?l=${id}&pm=m&state=canceled_by_teacher`}>{i18n.t('views.lessons.actions.canceled_by_teacher')}</Link>
        </>
      )
    }
    if (user.role == "Admin") {
      return (
        <>
          <Link className="lesson-state-actions" to={`?l=${id}&pm=m&state=admin_canceled_by_teacher`}>{i18n.t('views.lessons.actions.admin_canceled_by_teacher')}</Link>
          <Link className={`lesson-state-actions ${!user.is_partial_admin && 'disabled'}`} to={user.is_partial_admin && `?l=${id}&pm=m&state=admin_canceled`}>{i18n.t('views.lessons.actions.admin_canceled')}</Link>
        </>
      )
    }
    if (user.role == "Student" && event?.student_name !== "collective") {

      const userLocale = getUserLocale() === 'fr' ? 'fr' : 'en';

      const eventStartDate =new Date(event.start).toISOString().split('T')[0]
      const currentDate = new Date().toISOString().split('T')[0]

      const eventTime  = new Date(event.start).toLocaleTimeString(userLocale)
      const currentTime = new Date().toLocaleTimeString(userLocale)

      let isPassed =( eventStartDate === currentDate) && currentTime >= eventTime

      return <Link onClick={(e)=>{
        (isPassed) && e.preventDefault()
      }} className={`lesson-state-actions ${isPassed && 'isPassed'} `} to={`?l=${id}&pm=m&state=canceled`}>{i18n.t('views.lessons.actions.canceled')}</Link> }
  }

  // if state == passed
  if (state == "passed") {
    if (user.role == "Admin") { return <Link className="lesson-state-actions" to={`?l=${id}&pm=m&state=admin_canceled_by_teacher`}>{i18n.t('views.lessons.actions.admin_canceled_by_teacher')}</Link> }
    if (user.role == "Teacher") { return <Link className="lesson-state-actions" to={`?l=${id}&pm=m&state=noshow`}>{i18n.t('views.lessons.actions.noshow')}</Link> }
  }

  // if state == done
  if (state == "done") {
    if (user.role == "Admin") {
      return (
        <>
          <Link className="lesson-state-actions" to={`?l=${id}&pm=m&state=passed`}>{i18n.t('views.lessons.actions.passed')}</Link>
          <Link className={`lesson-state-actions ${!user.is_partial_admin && 'disabled'}`} to={user.is_partial_admin && `?l=${id}&pm=m&state=admin_canceled_by_teacher`}>{i18n.t('views.lessons.actions.admin_canceled_by_teacher')}</Link>
        </>
      )
    }
  }

  // if state == late_cancelation
  if (state == "late_cancelation") {
    //handle temporality (lesson in the past or not) ?
    if (user.role == "Admin") {
      if (!is_passed) {
        return (
          <>
            <Link className={`lesson-state-actions ${!user.is_partial_admin && 'disabled'}`} to={user.is_partial_admin && `?l=${id}&pm=m&state=rebook`}>{i18n.t('views.lessons.actions.rebook')}</Link>
            <Link className={`lesson-state-actions ${!user.is_partial_admin && 'disabled'}`} to={user.is_partial_admin && `?l=${id}&pm=m&state=passed`}>{i18n.t('views.lessons.actions.passed')}</Link>
            <Link className={`lesson-state-actions ${!user.is_partial_admin && 'disabled'}`} to={user.is_partial_admin && `?l=${id}&pm=m&state=admin_canceled`}>{i18n.t('views.lessons.actions.admin_canceled')}</Link>
            <Link className={`lesson-state-actions ${!user.is_partial_admin && 'disabled'}`} to={user.is_partial_admin && `?l=${id}&pm=m&state=admin_canceled_by_teacher`}>{i18n.t('views.lessons.actions.admin_canceled_by_teacher')}</Link>
          </>
        )
      } else {
        return (
          <>
            <Link className={`lesson-state-actions ${!user.is_partial_admin && 'disabled'}`} to={user.is_partial_admin && `?l=${id}&pm=m&state=admin_canceled`}>{i18n.t('views.lessons.actions.admin_canceled')}</Link>
            <Link className={`lesson-state-actions ${!user.is_partial_admin && 'disabled'}`} to={user.is_partial_admin && `?l=${id}&pm=m&state=admin_canceled_by_teacher`}>{i18n.t('views.lessons.actions.admin_canceled_by_teacher')}</Link>
          </>
        )
      }
    }
  }

  // if state == canceled
  if (state == "canceled") {
    //handle temporality (lesson in the past or not)

  }

  // if state == noshow
  if (state == "noshow") {
    if (event?.pending_ticket) {
      if (user.role == "Teacher") { return <Link className="lesson-state-actions" to={`?l=${id}&pm=m&state=cancel-recredit`}>{i18n.t('views.lessons.actions.cancel_recredit')}</Link> }
      if (user.role == "Admin") {
        return (
          <>
            <Link className={`lesson-state-actions ${!user.is_partial_admin && 'disabled'}`} to={user.is_partial_admin && `?l=${id}&pm=m&state=passed`}>{i18n.t('views.lessons.actions.passed')}</Link>
            <Link className={`lesson-state-actions ${!user.is_partial_admin && 'disabled'}`} to={user.is_partial_admin && `?l=${id}&pm=m&state=admin_canceled_by_teacher`}>{i18n.t('views.lessons.actions.admin_canceled_by_teacher')}</Link>
          </>
        )
      }
    } else {
      // teacher => invoice sent or recredit
      if (user.role == "Teacher") { return <Link className="lesson-state-actions" to={`?l=${id}&pm=m&state=recredit`}>{i18n.t('views.lessons.actions.recredit')}</Link> }
      if (user.role == "Admin") {
        return (
          <>
            <Link className={`lesson-state-actions ${!user.is_partial_admin && 'disabled'}`} to={user.is_partial_admin && `?l=${id}&pm=m&state=passed`}>{i18n.t('views.lessons.actions.passed')}</Link>
            <Link className={`lesson-state-actions ${!user.is_partial_admin && 'disabled'}`} to={user.is_partial_admin && `?l=${id}&pm=m&state=admin_canceled_by_teacher`}>{i18n.t('views.lessons.actions.admin_canceled_by_teacher')}</Link>
          </>
        )
      }
    }

  }

  // if state == recredit
  if (state == "recredit") {
    if (user.role == "Admin") {
      return (
        <>
          <Link className="lesson-state-actions" to={`?l=${id}&pm=m&state=passed`}>{i18n.t('views.lessons.actions.passed')}</Link>
          <Link className="lesson-state-actions" to={`?l=${id}&pm=m&state=admin_canceled_by_teacher`}>{i18n.t('views.lessons.actions.admin_canceled_by_teacher')}</Link>
        </>
      )
    }
  }

  if (state == "canceled_by_teacher") {
    if (user.role == "Admin") {
      if (!is_passed) {
        return (
          <>
            <Link className="lesson-state-actions" to={`?l=${id}&pm=m&state=rebook`}>{i18n.t('views.lessons.actions.rebook')}</Link>
            <Link className="lesson-state-actions" to={`?l=${id}&pm=m&state=admin_canceled`}>{i18n.t('views.lessons.actions.admin_canceled')}</Link>
          </>
        )
      } else {
        return (
          <>
            <Link className="lesson-state-actions" to={`?l=${id}&pm=m&state=passed`}>{i18n.t('views.lessons.actions.passed')}</Link>
          </>
        )
      }
    }
  }

  // if state == null(empty)
  if (state === null) {
    return <Link className={`lesson-state-actions ${!user.is_partial_admin && 'disabled'}`} to={user.is_partial_admin && `?l=${event?.id}&pm=m&state=admin_canceled`}>{i18n.t('views.lessons.actions.admin_canceled')}</Link>
  }

};

export default LessonActions;
