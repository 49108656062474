import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeacher } from "./teachersSlice";
import TeacherProfile from "./teacher_profile";
import Modal from "../toolbox/modal";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Popover } from "react-bootstrap";
import AvatarFillRate from "./components/avatar_fill_rate";

const Avatar = ({ calendar }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.userData)
  const teacherId = user.teacher
  const teacher = useSelector(state => state.teachers.teacherShow)
  const ref = useRef(null);
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)
  const teacherIdAdminAgenda = useSelector(state => state.filters['filtersLesson'].teachers)
  const profile = useSelector((state) => state.user.currentProfile)


  useEffect(() => {
    if (teacherId) {
      dispatch(getTeacher(teacherId))
    }
  }, [teacherId, profile]);

  useEffect(() => {
    if (teacherIdAdminAgenda) {
      dispatch(getTeacher(teacherIdAdminAgenda?.value))
    }
  }, [teacherIdAdminAgenda, profile]);

  const showModal = () => {
    ref.current?.onShow()
  }

  if (calendar) {
    return (
      <div className={`calendar-avatar-container ${responsiveOne && "responsive"}`}>


        <div className={`avatar ${responsiveOne && "responsive"}`} style={{ backgroundImage: `url(${teacher?.avatar?.file_url}` }} onClick={showModal}></div>
        {user?.role === 'Admin' && (
          <>
            <AvatarFillRate fill_rate={teacher.fill_rate} />
          </>

        )}
        <Modal ref={ref}>
          <TeacherProfile />
        </ Modal>
      </div>
    )
  } else {
    return (
      <>
        <div className={`avatar ${responsiveOne && "responsive"}`} style={{ backgroundImage: `url(${teacher?.avatar?.file_url}` }}></div>
        <Modal ref={ref}>
          <TeacherProfile />
        </ Modal>
      </>
    )
  }


};

export default Avatar;
