import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OpenInvoice from "./openInvoice";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";


export default function RenderAlertIcon({date, status}) {

  const user = useSelector(state => state.user.userData)

  const [invoiceMonth, invoiceYear] = date.split(' -')

  let currentMonth = new Date().getMonth()+1
  let currentYear = new Date().getFullYear()


  const condition =( user?.teacher_has_late_invoice && status === 'pending' && (currentMonth == invoiceMonth && currentYear == invoiceYear ))


  return (
    <>
      { condition && (
        <span className="invoice-alert-icon" >
        <FontAwesomeIcon className="exclamation-mark" color="#BE2522" icon={faCircleExclamation} />

        </span>
      )}
    </>

  )
}
