import React from "react";
import Status from "../components/status";
import i18n from '../../../i18n'
import OpenInvoice from "../components/openInvoice";

const columns = [
  {
    name: "id",
    selector: row => row.id,
    omit: true
  },
  {


    name: `${i18n.t('views.invoices.invoice')}`,
    selector: row => {
      return (
        <OpenInvoice row={row}>
          <div className="table-sub-list">
            <div className="list-item">{row.month_year}</div>
            <div className="list-item"><div className="ref">{row.ref}</div></div>
          </div>
        </OpenInvoice>

      )
    },
  },
  {
    name: `${i18n.t('views.invoices.amount')}`,
    selector: row => row.fixed_amount_incl,
  },
  {
    name: `${i18n.t('views.invoices.status')}`,
    selector: row => {
      return (
        <OpenInvoice row={row}>
          <Status status={row.status} date={row.month_year} />
        </OpenInvoice>
      )
    }

  }

];

export default columns
