import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons';
import i18n from "../../../i18n";

const AvailabilitiesDetails = () => {
  const location = useLocation();
  const search = location.search;
  const ids = new URLSearchParams(search).get('avs');
  const [list, setList] = useState([])

  useEffect(() => {
    if (ids && ids.length > 0) {
      const url = `/api/v1/availabilities/list?availabilities_id=${ids}&mode=teachers`;
      const promise = fetch(url, { credentials: "same-origin" }).then(r =>
        r.json()
      ).then(details => {
        setList(details);
      });
    }
  }, [ids])



  const renderAttendances = (lesson_type, attendances) => {
    if (lesson_type == 'collective' || attendances) {
      let att = attendances ? attendances : 0
      return (
        <>
          {[...Array(5)].map((_, i) => {
            return i < att ? <FontAwesomeIcon icon={faUser} color="#282828" size="lg" pull="left" /> : <FontAwesomeIcon icon={faUser} color="#E6E6E6" size="lg" pull="left" />
          })
          }
        </>
      )
    } else {
      return <FontAwesomeIcon icon={faUser} color="#E6E6E6" size="lg" pull="left" />
    }
  }

  const renderAvailabilitiesInfo = (list) => {
    return (
      <table className="availabilities-list">
        <thead>
          <tr>
            <th  >{i18n.t('activerecord.attributes.availability.list.headers.teacher')}</th>
            <th>{i18n.t('activerecord.attributes.availability.list.headers.french_level')}</th>

            <th>{i18n.t('activerecord.attributes.availability.list.headers.nationality')}</th>
            <th>{i18n.t('activerecord.attributes.availability.list.headers.english_level')}</th>
            <th>{i18n.t('activerecord.attributes.availability.list.headers.professional_skills')}</th>
            {/* <th>{i18n.t('activerecord.attributes.availability.list.headers.delay')}</th> */}
            <th>{i18n.t('activerecord.attributes.availability.list.headers.lesson')}</th>
            <th>{i18n.t('activerecord.attributes.availability.list.headers.note')}</th>
            {/* <th>{i18n.t('activerecord.attributes.availability.list.headers.type')}</th>
                 <th>{i18n.t('activerecord.attributes.availability.list.headers.theme')}</th>
                 <th>{i18n.t('activerecord.attributes.availability.list.headers.students')}</th> */}
            <th>%M</th>
            <th>%M+1</th>
            <th>%M+2</th>
            <th>{i18n.t('activerecord.attributes.availability.list.headers.teacher_rate')}</th>
          </tr>
        </thead>
        <tbody>
          {
            list?.map((el, idx) => {
              let link = `/teacher_agenda?teacher_name=${el['teacher_name']}&teacher_id=${el['id']}`
              return (
                <tr key={idx} className="black-link yellow-hover">
                  <td className="teacher-name"><a className="black-link yellow-hover" href={link}>{el["teacher_name"]}</a></td>
                  <td style={{ whiteSpace: 'nowrap' }} className="french_level"><a className="black-link yellow-hover" href={link}>{el["french_level"]?.join(', ')}</a></td>
                  <td className="nationality"><a className="black-link yellow-hover" href={link}>{el["nationality"]}</a></td>
                  <td style={{ whiteSpace: 'nowrap' }} className="english_level"><a className="black-link yellow-hover" href={link}>{el["english_level"]?.join(', ')}</a></td>

                  <td className="specialties"><a className="black-link yellow-hover" href={link}>{Array.isArray(el["specialties"]) ? el["specialties"]?.join(', ') : ""}</a></td>
                  {/* <td className="delay"><a className="black-link yellow-hover" href={link}>{el["delay"]}</a></td> */}
                  <td className="lesson_type"><a className="black-link yellow-hover" href={link}>{el["lesson_type"]}</a></td>
                  {/* <td className="english_type"><a className="black-link yellow-hover" href={link}>{el["english_type"]}</a></td>
                         <td className="theme"><a className="black-link yellow-hover" href={link}>{el["theme"]}</a></td>
                         <td className="attendances"><a className="black-link yellow-hover" href={link}>{renderAttendances(el["lesson_type"], el["attendances"])}</a></td> */}
                  <td className="note"><a className="black-link yellow-hover" href={link}>{el["rating"]}</a></td>
                  {/* <td className="attendances"><a className="black-link yellow-hover" href={link}>{el["fill_rate"] == '-' ? el["fill_rate"] : `${el["fill_rate"]}%`}</a></td> */}

                  <td className="attendances"><a className="black-link yellow-hover" href={link}>{el["fill_rate"] == '-' ? el["fill_rate"] : `${el["fill_rate"]['0-30_days']}%`}</a></td>
                  <td className="attendances"><a className="black-link yellow-hover" href={link}>{el["fill_rate"] == '-' ? el["fill_rate"] : `${el["fill_rate"]['30-60_days']}%`}</a></td>
                  <td className="attendances"><a className="black-link yellow-hover" href={link}>{el["fill_rate"] == '-' ? el["fill_rate"] : `${el["fill_rate"]['60-90_days']}%`}</a></td>

                  <td className="day_price"><a className="black-link yellow-hover" href={link}>{el["day_price"] == '-' ? el["day_price"] : `${el["day_price"]}`}</a></td>
                </tr>
              )
            }
            )
          }
        </tbody>
      </table>
    )
  }

  return (
    <>
      <div className="availabilities-details">
        {renderAvailabilitiesInfo(list)}
      </div>
    </>
  )
};

export default AvailabilitiesDetails;
