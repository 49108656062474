import React, { useEffect, useRef, useState } from "react";
import Avatar from "../teachers/avatar";
import CreditCount from "../lessons/components/credit_count";
import getUserLocale from "get-user-locale";
import { useSelector } from "react-redux";
import throttle from "lodash/throttle";
import i18n from "../../i18n";


export default function ResponsiveAgendaHeader({ dayData, setSelectedDate, selectedDate, changeDate, startAt, setSelectedDayDate, sameDay }) {


  const scrollRef = useRef(null)

  const user = useSelector((state) => state.user?.userData);

  useEffect(() => {

    scrollRef.current.scrollTo({
      left: 7,
      behavior: "smooth",
    });

  }, [])

  const formatMonth = () => {
    if (dayData) {
      const userLocale = getUserLocale() === 'fr' ? 'fr' : 'en';
      const options = { month: "long", year: "numeric" };
      let formattedMonth = new Intl.DateTimeFormat(userLocale, options).format(
        dayData[0]?.date
      );

      return formattedMonth;
    }

  };

  const formatDateBasedOnLocale = (date) => {
    const userLocale = getUserLocale() === 'fr' ? 'fr' : 'en';
    const options = { weekday: "short" };
    let formattedDate = new Intl.DateTimeFormat(userLocale, options).format(
      date
    );

    if (userLocale.includes("fr")) {
      formattedDate =
        formattedDate.split(".")[0]?.charAt(0)?.toUpperCase() +
        formattedDate.split(".")[0]?.slice(1);
    } else {
      formattedDate =
        formattedDate.split(" ")[0]?.charAt(0)?.toUpperCase() +
        formattedDate.split(" ")[0]?.slice(1);
    }

    return formattedDate;
  };


  const getColors = (item) => {
    const itemDateString = item?.toDateString();
    const isToday = itemDateString === new Date()?.toDateString();
    const selected = itemDateString === new Date(selectedDate)?.toDateString();

    if (isToday && !selectedDate) {
      return {
        backgroundColor: '#1ABC9C',
        textColor: '#FFFFFF',
      };
    }

    else if (isToday && (new Date().getDate() === new Date(selectedDate).getDate())) {
      return {
        backgroundColor: '#1ABC9C',
        textColor: '#FFFFFF',
      };
    }
    else if (isToday && !(new Date().getDate() === new Date(selectedDate).getDate())) {
      return {
        backgroundColor: 'transparent',
        textColor: '#1ABC9C',
      };
    }
    else if (selected) {
      return {
        backgroundColor: '#7F8C8D',
        textColor: '#FFFFFF',
      };
    }
    else {
      return {
        backgroundColor: 'transparent',
        textColor: 'white',
      };
    }
  };

  const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);


  const handleScroll = throttle(() => {

    if (scrollRef.current) {


      const scrollLeft = scrollRef.current.scrollLeft;

      const scrollWidth = scrollRef.current.scrollWidth;
      const clientWidth = scrollRef.current.clientWidth;


      if (isMobile) {
        if (scrollLeft >= scrollWidth - clientWidth +50  ) {
          const newDate = new Date(startAt);
          let newStartAt = newDate.setDate(newDate.getDate() + 7);

          scrollRef.current.scrollTo({
            left:0,
            behavior: "smooth",
          });

          changeDate(newStartAt);



        }

        if (scrollLeft <= -50) {
          const newDate = new Date(startAt);
          let newStartAt = newDate.setDate(newDate.getDate() - 7);

          scrollRef.current.scrollTo({
            left: 7,
            behavior: "smooth",
          });

          changeDate(newStartAt);



        }

      }
      else {

        //future
        if (scrollLeft +0.5  >= scrollWidth - clientWidth ) {
          const newDate = new Date(startAt);
          let newStartAt = newDate.setDate(newDate.getDate() + 7);

          changeDate(newStartAt);

          scrollRef.current.scrollTo({
            left: 3,
            behavior: "smooth",
          });
        }

        //past
        if (scrollLeft <= 0) {
          const newDate = new Date(startAt);
          let newStartAt = newDate.setDate(newDate.getDate() - 7);

          changeDate(newStartAt);
          scrollRef.current.scrollTo({
            left: 7,
            behavior: "smooth",
          });

        }
      }

    }

  }, isMobile ? 0 : 700)




  return (
    <div className="headerContainer"  >

      {/* first line of header */}
      <div style={{ justifyContent: 'flex-start', flexDirection: 'row', width: '100%', minHeight: '100px', display: 'flex' }} >

        <div style={{ width: '65%', display: 'flex', justifyContent: 'flex-start', color: 'white', padding: '30px' }} >
          <span style={{ fontSize: '18px', fontFamily: 'Montserrat', color: 'white', fontWeight: '700' }} >
            {formatMonth()}

          </span>
          <div style={{ marginLeft: '10px' }}>
            <CreditCount index='agenda' />

          </div>
        </div>
        <div style={{ width: '35%', flexShrink:0 }}>
          {user?.role === 'Student' && <Avatar calendar />}

        </div>

      </div>

      {/* days area */}
      <div ref={scrollRef} onScroll={handleScroll} className="dateAreaContainer" >
        {dayData?.map((item, index) => {
          const formattedDate = formatDateBasedOnLocale(item?.date);
          return (
            <div style={{width:!isMobile && '14.3%'}}  className="dateArea"
              key={index}
              onClick={() => {
                setSelectedDayDate(new Date(item?.date).getDay())
                setSelectedDate(item?.date)
              }}
            >
              <div style={{}} className="dateHeaderDateText" >
                {formattedDate}
              </div>
              <span style={{
                backgroundColor: getColors(item?.date).backgroundColor,
                color: getColors(item?.date).textColor
              }} className="dateHeaderText" >
                {item?.date?.getDate()}
              </span>

            </div>
          );
        })}
      </div>


    </div>
  );
}
