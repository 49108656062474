import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import EventDetail from './event_detail';
import { Link, useLocation } from "react-router-dom";
import zIndex from '@mui/material/styles/zIndex';
import i18n from '../../../i18n'

const Event = (eventInfo) => {



  const getTime = (firstDate, secondDate) => {
    let r = firstDate.replace(' ', 'T')
    let s = secondDate.replace(' ', 'T')

    let d = new Date(r),
      hours = '' + d.getHours(),
      minutes = '' + d.getMinutes()

    let se = new Date(s),
      hoursSecond = '' + se.getHours(),
      minutesSecond = '' + se.getMinutes()


    if (minutes.length < 2)
      minutes = '0' + minutes;
    if (minutesSecond.length < 2)
      minutesSecond = '0' + minutesSecond;

    const firstDateFormattedHours = `${hours}:${minutes}`;
    const secondDateFormatted = `${hoursSecond}:${minutesSecond}`;
    return firstDateFormattedHours + ' ' + secondDateFormatted
  }

  const overlay = (
    <Popover
      style={{ position: 'absolute', zIndex: eventInfo?.mobileOne && '100' }}
      id="popover-basic">
      <EventDetail event={eventInfo?.event?._def || eventInfo} />
    </Popover>
  )

  // case availability
  if (eventInfo?.event?.title == "Availability" || eventInfo?.title == "Availability") {
    return (
      <div id={`event-${eventInfo?.event?._def?.id || eventInfo?.id}`} className={`event availability ${eventInfo?.mobileOne && 'mobileOne'}`}>
        <Link className="lesson-state-actions" to={`?a=${eventInfo?.event?.id || eventInfo?.id}&pm=m&state=booking`}>
          <div style={{ width: eventInfo?.mobileOne ? '100%' : '', display: 'flex', alignItems: 'flex-end' }} className={`${!eventInfo?.mobileOne && "inner-event"} `} >
            {eventInfo?.mobileOne && <span style={{ marginTop: eventInfo?.mobileOne ? '10px' : '0px' }} className="spanText"> {getTime(eventInfo?.start, eventInfo?.end)} </span>}

          </div>
        </Link>
      </div>
    )
    // case closing availability
  } else if (eventInfo?.event?._def?.extendedProps?.status == "canceling" || eventInfo?.extendedProps?.status == "canceling" || eventInfo?.status == "canceling") {
    return (
      <div id={`event-${eventInfo?.event?._def?.id || eventInfo?.id}`} className={`event canceling-availability ${eventInfo?.mobileOne && 'mobileOne'}`}>
        <Link className="availabilities-actions" to={`/t_tickets/availability`}>
          <div style={{ width: eventInfo?.mobileOne ? '100%' : '' }} className={`${!eventInfo?.mobileOne && "inner-event"} `} >
            <span className={`${eventInfo?.mobileOne && "spanTextTitle"}`} style={{ color: eventInfo?.mobileOne ? '' : "grey" }}>{eventInfo?.event?.title || eventInfo?.title}</span>
          </div>
        </Link>
      </div>
    )
    // case pending recredit lesson
  } else if (eventInfo?.event?._def?.extendedProps?.pending_ticket || eventInfo?.extendedProps?.pending_ticket) {
    return (
      <div id={`event-${eventInfo?.event?._def?.id || eventInfo?.id}`} className={`event lesson pending-ticket ${eventInfo?.event?._def?.extendedProps?.lesson_state || eventInfo?.extendedProps?.lesson_state} ${eventInfo?.mobileOne && 'mobileOne'}`}>
        <OverlayTrigger key={eventInfo?.event?._def?.id || eventInfo?.id} trigger="click" containerPadding={20} rootClose overlay={overlay}>
          <div style={{ width: eventInfo?.mobileOne ? '100%' : '' }} className={`${!eventInfo?.mobileOne && "inner-event"} `} >
            <span className={`${eventInfo?.mobileOne && "spanTextTitle"}`} >{eventInfo?.event?.title || eventInfo?.title}</span>
            {eventInfo?.mobileOne && <span className="spanText" style={{ color: (eventInfo?.mobileOne && eventInfo?.extendedProps?.lesson_state === 'booked') ? 'white' : '' }} > {getTime(eventInfo?.start, eventInfo?.end)} </span>}

          </div>
        </OverlayTrigger>
      </div>
    )
    // case interactable lesson
  } else if (eventInfo?.event?._def?.extendedProps?.is_current_user_lesson || eventInfo?.extendedProps?.is_current_user_lesson) {
    return (
      <div id={`event-${eventInfo?.event?._def?.id || eventInfo?.id}`} className={`event lesson ${eventInfo?.event?._def?.extendedProps?.lesson_state || eventInfo?.extendedProps?.lesson_state} ${eventInfo?.mobileOne && 'mobileOne'}`}>
        <OverlayTrigger key={eventInfo?.event?._def?.id || eventInfo?.id} trigger="click" containerPadding={20} rootClose overlay={overlay}>
          <div style={{ width: eventInfo?.mobileOne ? '100%' : '' }} className={`${!eventInfo?.mobileOne && "inner-event"} `} >
            <span className={`${eventInfo?.mobileOne && "spanTextTitle"}`} style={{ color: (eventInfo?.mobileOne && eventInfo?.extendedProps?.lesson_state === 'booked') ? 'white' : '' }}>{eventInfo?.event?.title || eventInfo?.title}</span>
            {eventInfo?.mobileOne && <span className="spanText" style={{ color: (eventInfo?.mobileOne && eventInfo?.extendedProps?.lesson_state === 'booked') ? 'white' : '' }} > {getTime(eventInfo?.start, eventInfo?.end)} </span>}

          </div>
        </OverlayTrigger>
      </div>
    )
    // case not interactable lesson
  } else {
    return (
      <div id={`event-${eventInfo?.event?._def?.id || eventInfo?.id}`} className={`event lesson other-student ${eventInfo?.mobileOne && 'mobileOne'}`}>
        <div style={{ width: eventInfo?.mobileOne ? '100%' : '' }} className={`${!eventInfo?.mobileOne && "inner-event"} `} >
          <span className={`${eventInfo?.mobileOne && "spanTextTitle"}`} >{eventInfo?.event?.title || eventInfo?.title}</span>
          {eventInfo?.mobileOne && <span className="spanText" > {getTime(eventInfo?.start, eventInfo?.end)} </span>}
        </div>
      </div>
    )
  }

};


export default Event;
