
import React, { useRef, useState } from "react"

import { faPhoneAlt, faMale, faDownload, faExternalLinkAlt, faChevronUp, faChevronDown, faUserFriends, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../toolbox/modal"

import { Collapse } from "react-bootstrap";
import TeacherProfile from "../../../teachers/teacher_profile";

import i18n from '../../../../i18n'
import ReactHtmlParser from 'react-html-parser';
import Summary from "../summary";
import { useSelector } from "react-redux";


export default function StepTwo({ data }) {


  const [openSummary, setOpenSummary] = useState(false)
  const ref = useRef(null);
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)
  const {access_elearning} = useSelector((state)=>state.user.userData)


  const toggleSummary = () => {
    setOpenSummary(!openSummary)
  }

  const showModal = () => {
    ref.current?.onShow()
  }


  let attestation = data?.documents?.filter(t => { return t?.document_type == "training_attestation" })
  let link = attestation[0] ? attestation[0].url : "#"
  let showLink = attestation[0] ? "" : "hidden"


  //which date certifying_.end_at date or end?date
  const renderSummary = (data, type) => {
    if (data) {
      return (
        <>
          <div className="toggle-title" aria-expanded={openSummary} onClick={() => { toggleSummary() }}>
            <p>{i18n.t('views.trainings.chart.feedback')}</p>
            <FontAwesomeIcon size={"2x"} color="#FFC400" className="icon-details" icon={openSummary ? faChevronUp : faChevronDown} />
          </div>
          <Collapse in={openSummary}>
            <table className="training-table">
              {renderSummaryRows(type)}
            </table>
          </Collapse>
        </>
      )
    }
  }

  const renderSummaryRows = (type) => {
    if (type == "kickoff") {
      return (
        <tbody>
          <tr>
            <td className="strong">{i18n.t('views.trainings.chart.cv')}
            </td>
            <td>{data?.cv}</td>
          </tr>
          <tr>
            <td className="strong">{i18n.t("views.trainings.chart.field_history")}

            </td>
            <td>{data?.field_history}</td>
          </tr>
          <tr>
            <td className="strong">{i18n.t("views.trainings.chart.field_ambition")}
            </td>
            <td>{data?.field_ambitions}</td>
          </tr>
          <tr>
            <td className="strong">{i18n.t("views.trainings.chart.base_level")}
            </td>
            <td>{data?.base_level}</td>
          </tr>
        </tbody>
      )
    } else if (type == "midterm") {
      return (
        <tbody>
          <tr>
            <td className="strong">{i18n.t("views.lessons.review.teacher_note")}
              Résumé</td>
            <td>{data?.bpt_resume}</td>
          </tr>
        </tbody>
      )
    } else if (type == "final") {
      return (
        <tbody>
          <tr>
            <td className="strong">{i18n.t("views.lessons.review.teacher_note")}</td>
            <td>{data?.bff_resume}</td>
          </tr>
        </tbody>
      )
    }
  }




  return (
    <div className={`detail-box ${responsiveOne && "responsive"} `}>
      <div className={`informations ${responsiveOne && "responsive"} `}>
        <h3>{i18n.t('views.trainings.chart.start')} </h3>
        <h3>{i18n.t('views.trainings.chart.kickoff')}
        </h3>
        <p>
          {i18n.t('views.trainings.chart.pdl_with')}
          <strong> {data?.pdl_with} </strong>
          {i18n.t('views.trainings.chart.training_level')}
          <strong> {data?.base_level} </strong>
          {i18n.t("views.trainings.chart.gives_you")}
          {"  "}
          <strong>{data?.end_at}</strong> :
        </p>

        <p>- {i18n.t("views.trainings.chart.lesson_hour", { hours: data?.real_total_hours })} <a href="/planning" className="black-link">{i18n.t("navigation.student.lessons")}</a></p>
        <p>- {i18n.t('views.trainings.chart.elearning_access')} <a onClick={(e)=>{!access_elearning && e.preventDefault()}}  href="/elearnings" className={`black-link ${!access_elearning && 'not-allowed'} `}>{i18n.t("navigation.teacher.elearnings")}</a> </p>
        <p>- {i18n.t('views.trainings.chart.lesson_hour', { hours: data?.total_collective_credits })} <a href="/collective_planning" className="black-link">{i18n.t("navigation.teacher.collective_lessons")}</a></p>
        <p>- {ReactHtmlParser(i18n.t('views.trainings.chart.conference_access'))}</p>
        <p className={showLink}><FontAwesomeIcon className="icon-details" icon={faDownload} color="#AFAFAF" /> <a target="_blank" href={link} className="black-link">{i18n.t('documents.training_attestation')}</a></p>
        <p><FontAwesomeIcon className="icon-details" icon={faMale} color="#AFAFAF" /> <a onClick={() => showModal()} className="black-link"> {data?.teacher_name} </a> </p>


        {/* {renderSummary(data, "kickoff")} */}
        <Summary data={data} type={"kickoff"} />
      </div>
      <Modal ref={ref}>
        <TeacherProfile />
      </Modal>
    </div>

  )
}
