import getUserLocale from "get-user-locale";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ResponsiveAgendaHeader from "./responsive_agenda_header";
import i18n from "../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";


const ResponsiveAgenda = forwardRef(({ data, secondaryData, event, date, changeDate, selectable, startAt ,endAt}, ref) => {
  const user = useSelector((state) => state.user);
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const navigate = useNavigate();
    const refModal = useRef(null);

  const [mondayIssues, setMondayIssues] = useState([]);
  const [tuesdayIssues, setTuesdayIssues] = useState([]);
  const [wednesdayIssues, setWednesdayIssues] = useState([]);
  const [thursdayIssues, setThursdayIssues] = useState([]);
  const [fridayIssues, setFridayIssues] = useState([]);
  const [saturdayIssues, setSaturdayIssues] = useState([]);
  const [sundayIssues, setSundayIssues] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDayDate, setSelectedDayDate] = useState(new Date().getDay());
  const [dataSource, setDataSource] = useState([]);



  useEffect(() => {
    i18n.locale = getUserLocale() === 'fr' ? 'fr' : 'en'
  }, [])

  //using for group data
  useEffect(() => {
    const updatedData = [];


    const startDate = new Date(startAt);
    const endDate = new Date(endAt);

    secondaryData?.forEach((item) => {
      const itemDate = new Date(item?.start);

      if (itemDate >= startDate && itemDate <= endDate) {
        updatedData?.push(item);
      }
    });

    data?.forEach((item) => {
      const itemDate = new Date(item?.start);

      if (itemDate >= startDate && itemDate <= endDate) {
        updatedData?.push(item);
      }
    });

    const days = groupDatesByDay(updatedData);

    if (selectedDate) {
      const day = daysOfWeek[new Date(selectedDate).getDay()];
      setDataSource([
        {
          issues: Object.keys(days).includes(day)
            ? days[day].sort((a, b) => {
              const dateA = new Date(a?.start).getTime();
              const dateB = new Date(b?.start).getTime();
              if (isNaN(dateA) || isNaN(dateB)) {
                return 0;
              }
              return dateA - dateB;
            })
            : [],
          date: new Date(selectedDate),
        },
      ]);
    }
  }, [data, secondaryData, startAt, selectedDate]);

  // using for set select same day in next or before week after scrolling
  useEffect(() => {
    setSelectedDate(getDayDataForCalendarView(startAt, issues)?.sameDay?.date);
  }, [startAt]);

  //these 2 function is using for group data by date
  const getDay = (dateString) => {
    const date = dateString ? new Date(dateString).getDay() : '';
    return date !== null ? daysOfWeek[date] : '';
  };
  const groupDatesByDay = (datesArray) => {
    const grouped = {};

    datesArray?.forEach((dateString) => {
      const day = getDay(dateString?.start);

      if (!grouped[day]) {
        grouped[day] = [];
      }

      grouped[day].push(dateString);
    });

    return grouped;
  };

  //returns calendar header's date and day and if selected day is exist, set select same day in next week
  const getDayDataForCalendarView = (startAt, issues) => {
    const getMonday = (d) => {
      d = new Date(d);
      var day = d.getDay();
      var diff = d.getDate() - day + (day === 0 ? -6 : 1);
      d.setDate(diff);
      return d;
    }
    const startDate = getMonday(startAt);

    const orderedDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const dayData = orderedDays.map((day, index) => {
      const dayDate = new Date(startDate);
      dayDate.setDate(startDate.getDate() + (index));
      return {
        date: dayDate,
      };
    });

    const sameDay = dayData.find((day) => {
      return new Date(day?.date).getDay() === selectedDayDate
    });


    let result = {
      dayData,
      sameDay
    }

    return result
  };

  //returns item's date title
  const handleDate = (dayDate) => {
    const userLocale = getUserLocale() === 'fr-FR' ? getUserLocale() : 'en-EN';

    const weekday = dayDate.toLocaleDateString(userLocale, { weekday: 'long' });
    const day = dayDate.toLocaleDateString(userLocale, { day: 'numeric' });
    const month = dayDate.toLocaleDateString(userLocale, { month: 'long' });
    const year = dayDate.toLocaleDateString(userLocale, { year: 'numeric' });

    return `${weekday} ${day} ${month} ${year}`;
  };

  let issues = [
    { date: mondayIssues.date, data: mondayIssues?.issues },
    { date: tuesdayIssues.date, data: tuesdayIssues?.issues },
    { date: wednesdayIssues.date, data: wednesdayIssues?.issues },
    { date: thursdayIssues.date, data: thursdayIssues?.issues },
    { date: fridayIssues.date, data: fridayIssues?.issues },
    { date: saturdayIssues.date, data: saturdayIssues?.issues },
    { date: sundayIssues.date, data: sundayIssues?.issues },
  ];

  return (
    <div className="firstContainer">
      <div className="calendarHeader">
        <ResponsiveAgendaHeader dayData={getDayDataForCalendarView(startAt, issues)?.dayData} setSelectedDate={setSelectedDate} selectedDate={selectedDate} changeDate={changeDate} startAt={startAt} setSelectedDayDate={setSelectedDayDate} sameDay={getDayDataForCalendarView(startAt, issues)?.sameDay} />
      </div>

      <div className="calendarContent">

        {/* create availability- teacher space */}

        {user?.userData?.role === 'Teacher' && <div onClick={() =>
          {
            navigate(`?a_res=res&pm=m&state=new_av_res&selectedDate=${new Date(selectedDate)}`)
          }
          } style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}> <FontAwesomeIcon  size="xl" color="#1ABC9C" icon={faPlus} /></div>}

        {dataSource?.map((item, index) => (
          <div className={`itemContent ${item?.issues?.length === 0 && 'emptyItem'}`} key={index}>

            <span style={{ fontFamily: 'Montserrat', color: '#A6A6A6', fontWeight: 'bold', fontSize: '12px', marginBottom: '10px' }} >{handleDate(item.date)}</span>

            {item?.issues?.length > 0 ? (
              item?.issues?.map((eventInfo) =>
                React.cloneElement(event, { ...eventInfo, mobileOne: true })

              )
            ) : (
              i18n.t('views.lessons.review.course_slot_empty')
            )}
          </div>
        ))}

      </div>


    </div>
  );

})

export default ResponsiveAgenda;
