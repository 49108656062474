import React, { useState } from "react";
import DatePicker from "react-datepicker";

export default function ResponsiveAgendaCreateAvailability({ setStartTime, setEndTime, endTime, startTime, selectedDate }) {

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', position: 'absolute', top: '30px', padding: '10px' }}>
      <div>
        <DatePicker
          style={{ width: "300px" }}
          selected={startTime}
          onChange={(time) => {
            const selectedOne = new Date(selectedDate);
            const updatedDate = new Date(selectedOne.setHours(time.getHours(), time.getMinutes()));
            setStartTime(new Date(updatedDate));
          }}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          dateFormat="HH:mm"
          placeholderText="Start"
          minTime={new Date().setHours(6, 0)}
          maxTime={new Date().setHours(23, 59)}
          e
        />
      </div>
      <div>
        <DatePicker
          style={{ width: "300px" }}
          selected={endTime}
          onChange={(time) => {
            const selectedOne = new Date(selectedDate);
            const updatedDate = new Date(selectedOne.setHours(time.getHours(), time.getMinutes()));
            setEndTime(new Date(updatedDate))}}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          dateFormat="HH:mm"
          placeholderText="End"
          minTime={new Date().setHours(6, 0)}
          maxTime={new Date().setHours(23, 59)}
        />
      </div>
    </div>
  )
}
