import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import i18n from "../../../i18n";

export default function AvatarFillRate({ fill_rate }) {

  const teacher = useSelector(state => state.teachers.teacherShow);

  const circleColor = (fillRate) => {
    if (fillRate >= 100) {
      return "#cc8574";
    } else if (fillRate < 100 && fillRate >= 80) {
      return "#FFC400";
    } else {
      return "#1abc9c";
    }
  };

  const overlay = (key, value) => (
    <Popover id={`popover-${key}`}>
      <Popover.Content>
        {i18n.t(`${key}`)}<br />
        Règle : <br />
        [0%-80%] vert <br />
        [80%-100%] jaune <br />
        [&gt;100%] rouge
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      {Object.keys(teacher).length !== 0 &&
        Object.entries(fill_rate).map(([key, value]) => (
          <OverlayTrigger
            key={key}
            trigger={['hover', 'focus']}
            placement="left"
            overlay={overlay(key, value)}
          >
            <span style={{ display: 'inline-block', paddingTop: '18px', position: 'relative', marginRight: '10px' }}>
              <FontAwesomeIcon
                icon={faCircle}
                color={circleColor(value)}
              />
            </span>
          </OverlayTrigger>
        ))
      }
    </>
  );
}
