import React from "react";
import i18n from '../../../i18n'
import RenderAlertIcon from "./renderAlertIcon";


const Status = ({ status, date }) => {

  return (
    <div className={`invoice-status ${status}`}>
      {`${i18n.t(`views.invoices.states.${status}`)}`}

     {date && <RenderAlertIcon status={status} date={date} />}

    </div>
  )

};

export default Status;
